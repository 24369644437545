import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`As a supplier, showcase and detail your products for offsite construction.`}</p>
    </PageDescription>
    <p>{`Now that you are a supplier of products on KOPE, let’s get those products established.`}</p>
    <p>{` `}{` `}{`  ✔️ `}<em parentName="p">{`KOPE is a platform for offsite construction and our product registration is aligned to that method of building. If you cannot find where your product fits in, contact the team for assistance.`}</em></p>
    <p>{`Back on your home screen, choose `}<inlineCode parentName="p">{`New product`}</inlineCode>{`.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/6_products-new-product-button.png",
      "alt": "6_products-new-product-button.png"
    }}></img>
    <p>{`With KOPE, you are not simply making a digital record of a product. You are creating a dynamic recreation of how that product behaves in the real world. The better the information entered, the more accurately you will be able to see how it performs in real projects.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/7_products-register-product-pop-up.png",
      "alt": "7_products-register-product-pop-up.png"
    }}></img>
    <p>{`Let’s start with simple information such as the product name and description. When finished, hit `}<inlineCode parentName="p">{`Register`}</inlineCode>{` to add your product to your profile.`}</p>
    <p>{`With the basic information ready, you can now add more complex items such as performance specification, attributes, downloadable content, and related documentation.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/8_products-attributes.png",
      "alt": "8_products-attributes.png"
    }}></img>
    <p>{` `}{` `}{`   `}<em parentName="p">{`KOPE is currently rolling out the ability to add System Logic. If your product does not give access to this just yet, check in on our socials to get the latest updates on when this functionality will be active for your particular product category.`}</em></p>
    <p>{`By default, KOPE lists your products on the local hub you chose at registration. IF you would prefer that your particular product remains private, with use only given to users within your Organization, you can toggle the `}<inlineCode parentName="p">{`Listing Status`}</inlineCode>{` button.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/9_products-listing-status-toggle.png",
      "alt": "9_products-listing-status-toggle.png"
    }}></img>
    <p>{`Any changes you make to your products after initial creation will not be updated automatically. To update your product with any changes you make, click `}<inlineCode parentName="p">{`Update`}</inlineCode>{` in the top right.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/getting-started/10_products-update-product.png",
      "alt": "10_products-update-product.png"
    }}></img>
    <p>{`If you would like to delete some products from your profile, please get in touch with us directly and we will be happy to assist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      